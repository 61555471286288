import { useFormik } from "formik";
import countries from "libs/constants/countries";
import { score, ptescores } from "libs/constants/ieltsScore";
import qualifications from "libs/constants/qualifications";
import years from "libs/constants/years";
import { ELIGIBILITY } from "libs/firebase/constants";
import ROUTES from "libs/router/constants";
import React from "react";
import { useHistory } from "react-router";
import { useFirestore, useUser } from "reactfire";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";

function AddEligibilityForm({ setPopup }) {
  const history = useHistory();
  const eligibiltyRef = useFirestore().collection(ELIGIBILITY);
  const {
    data: { uid },
  } = useUser();

  const formik = useFormik({
    initialValues: {
      country: countries[0],
      highestQualification: qualifications[0],
      passingYear: years[0],
      percentage: 55,
      overAllMinimumIELTS: 5,
      overAllMaximumIELTS: 9,
      notLessThanIELTS: "",
      notLessThanInIELTS: "1",
      overAllMinimumPTE: 40,
      overAllMaximumPTE: 90,
      notLessThanPTE: "",
      notLessThanInPTE: "1",
      previousRefusal: "",
    },
    onSubmit: async ({
      country,
      highestQualification,
      passingYear,
      percentage,
      overAllMinimumIELTS,
      overAllMaximumIELTS,
      notLessThanIELTS,
      notLessThanInIELTS,
      overAllMinimumPTE,
      overAllMaximumPTE,
      notLessThanPTE,
      notLessThanInPTE,
      previousRefusal,
    }) => {
      try {
        await eligibiltyRef.add({
          country,
          highestQualification,
          passingYear,
          percentage,
          ielts: {
            overAllMinimumIELTS,
            overAllMaximumIELTS,
            notLessThanIELTS: +notLessThanIELTS,
            notLessThanInIELTS: +notLessThanInIELTS,
          },
          pte: {
            overAllMinimumPTE,
            overAllMaximumPTE,
            notLessThanPTE: +notLessThanPTE,
            notLessThanInPTE: +notLessThanInPTE,
          },
          uid,
          createdAt: new Date(),
          updatedAt: new Date(),
          previousRefusal,
        });
        setPopup(false);
        history.push(ROUTES.MANAGE_ELIGIBILITY);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="card eligible-check-form">
        <div className="card-body">
          {/* Personal Details */}
          <h4> Country </h4>
          <div className="group d-flex justify-content-between">
            <FormGroup>
              <Label>Country</Label>
              <Input
                type="select"
                name="country"
                required
                value={formik.values.country}
                onChange={formik.handleChange}
              >
                {countries.map((c, i) => (
                  <option key={i} value={c}>
                    {c}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </div>
          {/* Higher Eduction */}
          <h4> Highest Qualification and Ielts Requirement</h4>
          <div className="group d-flex justify-content-between">
            <FormGroup>
              <Label>Highest Qualification</Label>
              <Input
                type="select"
                name="highestQualification"
                required
                value={formik.values.highestQualification}
                onChange={formik.handleChange}
              >
                {qualifications.map((ql, i) => (
                  <option key={i} value={ql}>
                    {ql}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Passing Year</Label>
              <Input
                type="select"
                name="passingYear"
                required
                value={formik.values.passingYear}
                onChange={formik.handleChange}
              >
                {years.map((y, i) => (
                  <option value={y} key={i}>
                    {y} Onwards
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Percentage</Label>
              <Input
                type="number"
                required
                name="percentage"
                value={formik.values.percentage}
                onChange={formik.handleChange}
              />
            </FormGroup>
          </div>
          <h4> English Proficiency (IELTS) </h4>
          <div className="">
            <div className="ielts-pte-block group d-flex justify-content-between">
              <FormGroup>
                <Label>Over All Minimum </Label>
                <Input
                  type="number"
                  name="overAllMinimumIELTS"
                  value={formik.values.overAllMinimumIELTS}
                  onChange={formik.handleChange}
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label>Over All Maximum </Label>
                <Input
                  type="number"
                  name="overAllMaximumIELTS"
                  value={formik.values.overAllMaximumIELTS}
                  onChange={formik.handleChange}
                ></Input>
              </FormGroup>
            </div>
          </div>
          <div className="">
            <div className="ielts-pte-block group d-flex justify-content-between">
              <FormGroup>
                <Label>Not Less than </Label>
                <Input
                  type="select"
                  name="notLessThanIELTS"
                  required
                  value={formik.values.notLessThanIELTS}
                  onChange={formik.handleChange}
                >
                  <option value=""> -- Select --</option>
                  {score.map((sc, i) => (
                    <option key={i} value={sc}>
                      {sc}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>
                  Can have <strong>{formik.values.notLessThanIELTS}</strong> in
                  atmost
                </Label>
                <Input
                  type="select"
                  name="notLessThanInIELTS"
                  required
                  value={formik.values.notLessThanInIELTS}
                  onChange={formik.handleChange}
                >
                  <option value="1">1 Module</option>
                  <option value="2">2 Modules</option>
                  <option value="3">3 Modules</option>
                  <option value="4">4 Modules</option>
                </Input>
              </FormGroup>
            </div>
          </div>

          {/* ELIGIBILITY PTE */}
          <h4> English Proficiency (PTE) </h4>
          <div className="">
            <div className="ielts-pte-block group d-flex justify-content-between">
              <FormGroup>
                <Label>Over All Minimum PTE </Label>
                <Input
                  type="number"
                  name="overAllMinimumPTE"
                  value={formik.values.overAllMinimumPTE}
                  onChange={formik.handleChange}
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label>Over All Maximum PTE </Label>
                <Input
                  type="number"
                  name="overAllMaximumPTE"
                  value={formik.values.overAllMaximumPTE}
                  onChange={formik.handleChange}
                ></Input>
              </FormGroup>
            </div>
          </div>
          <div className="">
            <div className="ielts-pte-block group d-flex justify-content-between">
              <FormGroup>
                <Label>Not Less than </Label>
                <Input
                  type="select"
                  required
                  name="notLessThanPTE"
                  value={formik.values.notLessThanPTE}
                  onChange={formik.handleChange}
                >
                  <option value=""> -- Select --</option>
                  {ptescores.map((pt, i) => (
                    <option key={i} value={pt}>
                      {pt}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>
                  {" "}
                  Can have <strong>{formik.values.notLessThanInPTE}</strong> in
                  atmost{" "}
                </Label>
                <Input
                  type="select"
                  name="lessThanInPTE"
                  required
                  value={formik.values.notLessThanInPTE}
                  onChange={formik.handleChange}
                >
                  <option value="1">1 Module</option>
                  <option value="2">2 Modules</option>
                  <option value="3">3 Modules</option>
                  <option value="4">4 Modules</option>
                </Input>
              </FormGroup>
            </div>
          </div>
          {/*  */}
          <div className="">
            <div className="ielts-pte-block group d-flex justify-content-between">
              <FormGroup>
                <Label>Previous Refusal Creteria </Label>
                <Input
                  type="select"
                  name="previousRefusal"
                  required
                  defaultValue={formik.values.previousRefusal}
                  onChange={formik.handleChange}
                >
                  <option value=""> -- Select --</option>
                  <option value="allaccepted"> All Accepted </option>
                  <option value="notaccepted"> Not Accepted </option>
                  <option value="Australia">Not Accepted from Australia</option>
                  <option value="Canada">Not Accepted from Canada</option>
                  <option value="UK">Not Accepted from UK</option>
                </Input>
              </FormGroup>
            </div>
          </div>
        </div>
        <Button color="primary" className="my-3 d-flex mx-auto">
          Add Eligibility
        </Button>
      </div>
    </Form>
  );
}
export default AddEligibilityForm;
