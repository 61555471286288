import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap4-modal";
import DataTable from "react-data-table-component";

import AddEligibilityForm from "./AddEligibilityForm";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { ELIGIBILITY } from "libs/firebase/constants";
import moment from "moment";

const getScores = (obj, key) =>
  `${key.charAt(0).toUpperCase()}: ${obj.ielts[`${key}MinimumIELTS`]}-${
    obj.ielts[`${key}MaximumIELTS`]
  }`;

function AddEligibility(props) {
  const [popup, setPopup] = useState(false);
  let { data } = useFirestoreCollectionData(
    useFirestore().collection(ELIGIBILITY)
  );

  const columns = [
    {
      name: "Date",
      selector: "dateCreated",
      sortable: true,
    },
    {
      name: "Highest Qualification",
      selector: "highestQualification",
      sortable: true,
    },
    {
      name: "Country",
      selector: "country",
      sortable: true,
      right: true,
    },
    {
      name: "Percentage (%)",
      selector: "percentage",
      sortable: true,
      right: true,
    },
    {
      name: "IELTS SCORE",
      selector: "ielts",
      sortable: true,
      right: false,
    },
    {
      name: "Passing Year",
      selector: "passingYear",
      sortable: true,
      right: false,
    },
  ];

  useEffect(() => {
    if (props.location.pathname.indexOf("/add-eligibility") > -1) {
      setPopup(true);
    }
  }, [props.location.pathname]);

  data = data
    ?.map((obj) => ({
      ...obj,
      percentage: `${obj.percentage} %`,
      dateCreated: moment(new Date(obj.createdAt.toDate())).format("LL"),
      ielts: `${getScores(obj, "overAll")} | Not Less Than: ${
        obj?.ielts?.notLessThanIELTS
      } | Not Less Than in ${obj?.ielts?.notLessThanInIELTS} module`,
    }))
    ?.map((obj) => {
      delete obj?.createdAt;
      delete obj?.updatedAt;
      delete obj?.uid;
      delete obj?.NO_ID_FIELD;
      return obj;
    });

  const handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    console.log("Selected Rows: ", state.selectedRows);
  };

  return (
    <div className="">
      <div className="admin-eligibility-table">
        <DataTable
          title="Eligibility Lists"
          columns={columns}
          data={data}
          onSelectedRowsChange={handleChange}
          pagination
          selectableRows
          dense
          onRowClicked={(row) => console.log(row)}
          customStyles={{
            rows: {
              style: {
                cursor: "pointer",
              },
            },
          }}
        />
        <Modal dialogClassName="modal-lg" visible={popup}>
          <div className="modal-header">
            <h2 className="modal-title">Add New Eligibility Creteria!</h2>
          </div>
          <div className="modal-body">
            <AddEligibilityForm setPopup={setPopup} />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={(e) => {
                props.history.push({
                  pathname: "/manage-eligibility",
                });
                setPopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}
export default AddEligibility;
