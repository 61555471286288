import { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { useFirestore } from "reactfire";

import countries from "libs/constants/countries";
import qualifications from "libs/constants/qualifications";
import years from "libs/constants/years";
import { score } from "libs/constants/ieltsScore";
import { LEADS, PHONES_CAPTURED } from "libs/firebase/constants";
import ROUTES from "../../libs/router/constants";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";

function EligibilityForm(props) {
  const [examType, setExamType] = useState(false);
  const [clientIp, setClientIP] = useState(null);

  useEffect(() => {
    const publicIp = require("public-ip");
    (async () => {
      setClientIP(await publicIp.v4());
    })();
  }, []);

  function CalculateIelts(r, l, w, s) {
    let aggregate = (+r + +l + +w + +s) / 4;
    aggregate = Math.round(aggregate * 2) / 2;
    return aggregate;
  }
  function CalculatePTE(r, l, w, s, g, o, p, sp, v, wr) {
    let enablingSkills = (+g + +o + +p + +sp + +v + +wr) / 6;
    let aggregate = (+r + +l + +w + +s + enablingSkills) / 5;
    // aggregate = Math.round(aggregate * 2) / 2;
    return Math.floor(aggregate);
  }
  const history = useHistory();
  const leadsRef = useFirestore().collection(LEADS);
  const phonesRef = useFirestore().collection(PHONES_CAPTURED);

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      country: countries[0],
      highestQualification: qualifications[0],
      passingYear: years[0],
      percentage: "",
      refusal: "",
      refusalCountry: "",
      examGiven: "",
      reading: "",
      writing: "",
      listening: "",
      speaking: "",
      overAll: "",
      readingPTE: "",
      writingPTE: "",
      listeningPTE: "",
      speakingPTE: "",
      grammarPTE: "",
      oralFluencyPTE: "",
      pronunciationPTE: "",
      spellingPTE: "",
      vocabularyPTE: "",
      writtenDiscoursePTE: "",
      overAllPTE: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
      percentage: Yup.number()
        .required("Percentage is required")
        .positive("Please add valid Percentage"),
      reading: Yup.number("Value is not valid").positive(
        "Please add valid input"
      ),
      writing: Yup.number("Value is not valid").positive(
        "Please add valid input"
      ),
      listening: Yup.number("Value is not valid").positive(
        "Please add valid input"
      ),
      speaking: Yup.number("Value is not valid").positive(
        "Please add valid input"
      ),
      overAllPTE: Yup.number().positive(),
    }),
    onSubmit: async ({
      name,
      phone,
      email,
      country,
      highestQualification,
      passingYear,
      percentage,
      refusal,
      refusalCountry,
      examGiven,
      reading,
      writing,
      listening,
      speaking,
      overAll,
      readingPTE,
      writingPTE,
      listeningPTE,
      speakingPTE,
      grammarPTE,
      oralFluencyPTE,
      pronunciationPTE,
      spellingPTE,
      vocabularyPTE,
      writtenDiscoursePTE,
      overAllPTE,
    }) => {
      console.log(
        name,
        phone,
        email,
        country,
        highestQualification,
        passingYear,
        percentage,
        refusal,
        refusalCountry,
        examGiven,
        examType,
        reading,
        writing,
        listening,
        speaking,
        overAll,
        readingPTE,
        writingPTE,
        listeningPTE,
        speakingPTE,
        grammarPTE,
        oralFluencyPTE,
        pronunciationPTE,
        spellingPTE,
        vocabularyPTE,
        writtenDiscoursePTE,
        overAllPTE
      );
      try {
        // s
        // SEND TO FIREBASE COLLECTION SAYS LEADS
        await leadsRef.add({
          name,
          phone,
          email,
          countryIntrust: country,
          highestQualification,
          passingYear,
          gap: new Date().getFullYear() - parseInt(passingYear),
          percentage: +percentage,
          refusal,
          refusalCountry,
          examGiven,
          examType,
          ielts:
            examType == "PTE"
              ? {}
              : {
                  reading: +reading,
                  writing: +writing,
                  listening: +listening,
                  speaking: +speaking,
                  overAll: +overAll,
                },
          pte:
            examType == "IELTS"
              ? {}
              : {
                  reading: +readingPTE,
                  writing: +writingPTE,
                  listening: +listeningPTE,
                  speaking: +speakingPTE,
                  grammar: +grammarPTE,
                  oralFluency: +oralFluencyPTE,
                  pronunciation: +pronunciationPTE,
                  spelling: +spellingPTE,
                  vocabulary: +vocabularyPTE,
                  writtenDiscourage: +writtenDiscoursePTE,
                  overAll: +overAllPTE,
                },
          leadFrom: props.location.search.replace("?", ""),
          fromIp: clientIp,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
        history.push(ROUTES.THANKYOU_LEAD);
      } catch (error) {
        console.log(error);
      }
    },
  });
  function resetLanguageValues() {
    formik.values.reading = "";
    formik.values.writing = "";
    formik.values.listening = "";
    formik.values.speaking = "";
    formik.values.overAll = "";
    formik.values.readingPTE = "";
    formik.values.writingPTE = "";
    formik.values.listeningPTE = "";
    formik.values.speakingPTE = "";
    formik.values.overAllPTE = "";
  }
  useEffect(() => {
    const {
      reading,
      listening,
      writing,
      speaking,
      readingPTE,
      writingPTE,
      listeningPTE,
      speakingPTE,
      grammarPTE,
      oralFluencyPTE,
      pronunciationPTE,
      spellingPTE,
      vocabularyPTE,
      writtenDiscoursePTE,
    } = formik.values;
    if (
      examType === "IELTS" &&
      reading !== "" &&
      writing !== "" &&
      listening !== "" &&
      speaking !== ""
    ) {
      formik.values.overAll = CalculateIelts(
        reading,
        listening,
        writing,
        speaking
      );
    }
    if (
      examType === "PTE" &&
      readingPTE !== "" &&
      writingPTE !== "" &&
      listeningPTE !== "" &&
      speakingPTE !== "" &&
      grammarPTE != "" &&
      oralFluencyPTE != "" &&
      pronunciationPTE != "" &&
      spellingPTE != "" &&
      vocabularyPTE != "" &&
      writtenDiscoursePTE != ""
    ) {
      formik.values.overAllPTE = CalculatePTE(
        readingPTE,
        listeningPTE,
        writingPTE,
        speakingPTE,
        grammarPTE,
        oralFluencyPTE,
        pronunciationPTE,
        spellingPTE,
        vocabularyPTE,
        writtenDiscoursePTE
      );
    }
  });

  async function capturePhone(obj) {
    let phone = obj.currentTarget.value;
    if (phone.length == 10) {
      try {
        phonesRef?.doc(phone).set({
          createdAt: new Date(),
          phoneNumber: phone,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div className="container">
      <h1 className="text-center my-3">
        {" "}
        FIND OUT NOW: DO I MEET THE CRITERIA?
      </h1>
      <Form onSubmit={formik.handleSubmit}>
        <div className="card shadow mb-3 eligible-check-form">
          <div className="card-body">
            {/* Personal Details */}
            <h4>
              {" "}
              <i className="fa fa-user" />
              Personal Details
            </h4>
            <div className="group d-flex justify-content-between">
              <FormGroup>
                <Label for="exampleEmail">Name</Label>
                <Input
                  type="text"
                  required
                  name="name"
                  onBlur={formik.handleBlur}
                  invalid={!!formik.errors.name}
                  onChange={formik.handleChange}
                />
                {formik.touched.name && formik.errors.name && (
                  <FormFeedback>{formik.errors.name}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Phone</Label>
                <Input
                  type="text"
                  required
                  maxLength="10"
                  onKeyUp={formik.handleBlur}
                  onBlur={(e) => capturePhone(e)}
                  invalid={!!formik.errors.phone}
                  name="phone"
                  onChange={formik.handleChange}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <FormFeedback>{formik.errors.phone}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Email</Label>
                <Input
                  type="email"
                  required
                  name="email"
                  onChange={formik.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleSelect">Country</Label>
                <Input
                  type="select"
                  required
                  name="country"
                  onChange={formik.handleChange}
                >
                  {countries.map((c, i) => (
                    <option key={i}>{c}</option>
                  ))}
                </Input>
              </FormGroup>
            </div>
            {/* Higher Eduction */}
            <h4>
              {" "}
              <i className="fa fa-graduation-cap" aria-hidden="true"></i>
              Highest Qualification
            </h4>
            <div className="group d-flex justify-content-between">
              <FormGroup>
                <Label for="exampleSelect">Highest Qualification</Label>
                <Input
                  type="select"
                  required
                  name="highestQualification"
                  onChange={formik.handleChange}
                >
                  {qualifications.map((ql, i) => (
                    <option key={i}>{ql}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="exampleSelect">Passing Year</Label>
                <Input
                  type="select"
                  required
                  name="passingYear"
                  onChange={formik.handleChange}
                >
                  {years.map((y, i) => (
                    <option key={i}>{y}</option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="exampleSelect">Percentage</Label>
                <Input
                  type="text"
                  required
                  onBlur={formik.handleBlur}
                  name="percentage"
                  invalid={!!formik.errors.percentage}
                  onChange={formik.handleChange}
                />
                {formik.touched.percentage && formik.errors.percentage && (
                  <FormFeedback>{formik.errors.percentage}</FormFeedback>
                )}
              </FormGroup>
            </div>
            <h4>
              {" "}
              <i className="fa fa-ban"></i> Refusal Details{" "}
            </h4>
            <div className="group d-flex justify-content-between">
              <FormGroup>
                <Label for="exampleSelect">Do you have any refusals ? </Label>
                <Input
                  type="select"
                  required
                  name="refusal"
                  onChange={formik.handleChange}
                >
                  <option value="">-- Select -- </option>
                  <option value="yes"> Yes</option>
                  <option value="no">No </option>
                </Input>
              </FormGroup>
              {formik.values.refusal === "yes" && (
                <FormGroup>
                  <Label for="exampleSelect">Country of Refusal </Label>
                  <Input
                    type="select"
                    name="refusalCountry"
                    onChange={formik.handleChange}
                  >
                    <option value=""> -- Select --</option>
                    {countries.map((c, i) => (
                      <option key={i}>{c}</option>
                    ))}
                  </Input>
                </FormGroup>
              )}
            </div>
            <h4>
              {" "}
              <i className="fa fa-book" aria-hidden="true"></i>
              English Proficiency{" "}
            </h4>
            <div className="group">
              <FormGroup>
                <Label for="exampleSelect">Have you done IELTS / PTE ? </Label>
                <Input
                  type="select"
                  name="examGiven"
                  onChange={formik.handleChange}
                >
                  <option value="">-- Select -- </option>
                  <option value="yes"> Yes</option>
                  <option value="no">No </option>
                </Input>
              </FormGroup>
              {formik.values.examGiven === "yes" && (
                <div className="ielts-pte-block group d-flex justify-content-between">
                  <FormGroup>
                    <Label for="exampleSelect">Exam Type </Label>
                    <Input
                      type="select"
                      name="examType"
                      onChange={(e) => {
                        if (e.currentTarget.value !== examType) {
                          resetLanguageValues();
                          setExamType(e.currentTarget.value);
                        }
                      }}
                    >
                      <option value=""> -- Select --</option>
                      <option value="IELTS"> IELTS</option>
                      <option value="PTE">PTE </option>
                    </Input>
                  </FormGroup>
                </div>
              )}

              {examType === "IELTS" && (
                <div div className="form-group">
                  <div className="ielts-pte-block group d-flex justify-content-between">
                    <FormGroup>
                      <Label for="exampleSelect">Reading </Label>
                      <Input
                        type="select"
                        name="reading"
                        onBlur={formik.handleBlur}
                        invalid={!!formik.errors.reading}
                        defaultValue={formik.values.reading}
                        onChange={formik.handleChange}
                      >
                        <option value=""> -- Select --</option>
                        {score.map((sc, i) => (
                          <option key={i}> {sc}</option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Writing </Label>
                      <Input
                        type="select"
                        name="writing"
                        onBlur={formik.handleBlur}
                        invalid={!!formik.errors.writing}
                        defaultValue={formik.values.writing}
                        onChange={formik.handleChange}
                      >
                        <option value="">-- Select -- </option>
                        {score.map((sc, i) => (
                          <option key={i}> {sc}</option>
                        ))}
                      </Input>
                      {formik.touched.writing && formik.errors.writing && (
                        <FormFeedback>{formik.errors.writing}</FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Listening </Label>
                      <Input
                        type="select"
                        name="listening"
                        invalid={!!formik.errors.listening}
                        onBlur={formik.handleBlur}
                        defaultValue={formik.values.listening}
                        onChange={formik.handleChange}
                      >
                        <option value="">-- Select -- </option>
                        {score.map((sc, i) => (
                          <option key={i}> {sc}</option>
                        ))}
                      </Input>
                      {formik.touched.listening && formik.errors.listening && (
                        <FormFeedback>{formik.errors.listening}</FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Speaking </Label>
                      <Input
                        type="select"
                        onBlur={formik.handleBlur}
                        name="speaking"
                        invalid={!!formik.errors.speaking}
                        defaultValue={formik.values.speaking}
                        onChange={formik.handleChange}
                      >
                        <option value=""> -- Select --</option>
                        {score.map((sc, i) => (
                          <option key={i}> {sc}</option>
                        ))}
                      </Input>
                      {formik.touched.speaking && formik.errors.speaking && (
                        <FormFeedback>{formik.errors.speaking}</FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Overall </Label>
                      <Input
                        type="text"
                        disabled
                        name="overAll"
                        value={formik.values.overAll}
                      />
                    </FormGroup>
                  </div>
                </div>
              )}

              {examType === "PTE" && (
                <div className="form-group">
                  <div className="d-flex align-item-center flex-wrap justify-content-between">
                    <FormGroup>
                      <Label for="exampleSelect">Reading </Label>
                      <Input
                        type="number"
                        name="readingPTE"
                        onBlur={formik.handleBlur}
                        invalid={!!formik.errors.readingPTE}
                        required={examType === "PTE" ? true : false}
                        defaultValue={formik.values.readingPTE}
                        min="1"
                        onChange={formik.handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Writing </Label>
                      <Input
                        type="number"
                        name="writingPTE"
                        required={examType === "PTE" ? true : false}
                        defaultValue={formik.values.writingPTE}
                        onBlur={formik.handleBlur}
                        invalid={!!formik.errors.writingPTE}
                        onChange={formik.handleChange}
                        min="1"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Listening </Label>
                      <Input
                        type="number"
                        required={examType === "PTE" ? true : false}
                        name="listeningPTE"
                        defaultValue={formik.values.listeningPTE}
                        onBlur={formik.handleBlur}
                        invalid={!!formik.errors.listeningPTE}
                        onChange={formik.handleChange}
                        min="1"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Speaking </Label>
                      <Input
                        type="number"
                        required={examType === "PTE" ? true : false}
                        name="speakingPTE"
                        defaultValue={formik.values.speakingPTE}
                        onBlur={formik.handleBlur}
                        invalid={!!formik.errors.speakingPTE}
                        onChange={formik.handleChange}
                        min="1"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Grammar </Label>
                      <Input
                        type="number"
                        required={examType === "PTE" ? true : false}
                        name="grammarPTE"
                        defaultValue={formik.values.grammarPTE}
                        onBlur={formik.handleBlur}
                        invalid={!!formik.errors.grammarPTE}
                        onChange={formik.handleChange}
                        min="1"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Oral Fluency </Label>
                      <Input
                        type="number"
                        required={examType === "PTE" ? true : false}
                        name="oralFluencyPTE"
                        defaultValue={formik.values.oralFluencyPTE}
                        onBlur={formik.handleBlur}
                        invalid={!!formik.errors.oralFluencyPTE}
                        onChange={formik.handleChange}
                        min="1"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Pronunciation </Label>
                      <Input
                        type="number"
                        required={examType === "PTE" ? true : false}
                        name="pronunciationPTE"
                        defaultValue={formik.values.pronunciationPTE}
                        onBlur={formik.handleBlur}
                        invalid={!!formik.errors.pronunciationPTE}
                        onChange={formik.handleChange}
                        min="1"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Spelling </Label>
                      <Input
                        type="number"
                        required={examType === "PTE" ? true : false}
                        name="spellingPTE"
                        defaultValue={formik.values.spellingPTE}
                        onBlur={formik.handleBlur}
                        invalid={!!formik.errors.spellingPTE}
                        onChange={formik.handleChange}
                        min="1"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Vocabulary </Label>
                      <Input
                        type="number"
                        name="vocabularyPTE"
                        required={examType === "PTE" ? true : false}
                        defaultValue={formik.values.vocabularyPTE}
                        onBlur={formik.handleBlur}
                        invalid={!!formik.errors.vocabularyPTE}
                        onChange={formik.handleChange}
                        min="1"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Writing Discourse </Label>
                      <Input
                        type="number"
                        name="writtenDiscoursePTE"
                        required={examType === "PTE" ? true : false}
                        defaultValue={formik.values.writtenDiscoursePTE}
                        onBlur={formik.handleBlur}
                        invalid={!!formik.errors.writtenDiscoursePTE}
                        onChange={formik.handleChange}
                        min="1"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Overall </Label>
                      <Input
                        type="number"
                        required={examType === "PTE" ? true : false}
                        disabled
                        name="overAllPTE"
                        defaultValue={formik.values.overAllPTE}
                      />
                    </FormGroup>
                  </div>
                </div>
              )}
            </div>
            <Button
              color="primary"
              className="mt-3 mx-auto d-flex justify-content-center"
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default EligibilityForm;
