import React from "react";

function ThankYouLead() {
  return (
    <h1 className="text-center m-6">
      {" "}
      Thank you! Our team will get in touch with you soon.{" "}
    </h1>
  );
}

export default ThankYouLead;
