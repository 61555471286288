import { useProfile } from "libs/firebase/hooks";
import Logout from "modules/auth/Logout";
import React from "react";
import { Link } from "react-router-dom";

function Header() {
  const profile = useProfile();

  return (
    <div className="eligibility-header-outer">
      <div className="eligibility-header-inner">
        <Link to="/">
          <img src="images/mainlogo.png" alt="main-logo" />
        </Link>
        {!profile ? (
          <>
            <span>
              Helpline No: &nbsp;{" "}
              <a href="tel:+91-73875-73875">+91-73875-73875</a>
            </span>
            <span>
              <a href="mailto:applications@smartstudy.net.in">
                applications@smartstudy.net.in
              </a>
            </span>
          </>
        ) : (
          <Logout />
        )}
      </div>
    </div>
  );
}

export default Header;
