import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Col,
  Container,
  Row,
} from "reactstrap";
import { useAuth } from "reactfire";
import { toast, ToastContainer } from "react-toastify";
import { ROLE_COUNTRY_HEAD } from "libs/firebase/constants";
import ROUTES from "libs/router/constants";
import { useProfile } from "libs/firebase/hooks";

function LoginPage({ history }) {
  const auth = useAuth();
  const profile = useProfile();

  useEffect(() => {
    if (profile) {
      if (profile?.roles?.find((role) => role?.name === ROLE_COUNTRY_HEAD)) {
        history.push(ROUTES.ELIGIBILITY_DASHBOARD);
      }
    }
  }, [history, profile]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("invalid email address")
        .required("Email required"),
      password: Yup.string()
        .required("Password required")
        .min(6, "Password is too short - should be 6 chars minimum."),
    }),
    onSubmit: async ({ email, password }) => {
      try {
        await auth.signInWithEmailAndPassword(email, password);
      } catch (error) {
        console.log(error.message);
        toast.error(error.message);
      }
    },
  });

  return (
    <main className="vh-100">
      <Container fluid>
        <Row>
          <Col
            lg={12}
            md={12}
            sm={12}
            className="px-0 d-flex align-items-center"
            style={{
              backgroundImage: "url(images/immigration.jpg)",
              backgroundSize: "cover",
              height: "100vh",
            }}
          >
            <Col
              lg={4}
              md={6}
              sm={6}
              className="d-flex flex-column justify-content-center py-5 mx-auto bg-white mt-3 card"
            >
              <div className="d-flex justify-content-center">
                <img src="images/mainlogo.png" alt="logo" className="h-75" />
              </div>
              <div>
                <h2 className="font-weight-bold mb-3 text-center">Sign in</h2>
                <Form onSubmit={formik.handleSubmit}>
                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      invalid={!!formik.errors.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <FormFeedback>{formik.errors.email}</FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Password</Label>
                    <Input
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={!!formik.errors.password}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <FormFeedback>{formik.errors.password}</FormFeedback>
                    )}
                  </FormGroup>
                  <Button color="primary" block className="text-uppercase">
                    Sign In
                  </Button>
                  <ToastContainer />
                </Form>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </main>
  );
}

export default LoginPage;
