import { createContext, useContext, useEffect, useState } from "react";
import { useAuth, useFirestore } from "reactfire";
import { USERS } from "./constants";

const FirebaseContext = createContext();

const FirebaseAuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const auth = useAuth();
  const firestore = useFirestore();

  useEffect(() => {
    auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        firestore
          .collection(USERS)
          .doc(currentUser?.uid)
          .onSnapshot((snapshot) => setUser(snapshot.data()));
      }
    });
  }, [auth, firestore]);

  return (
    <FirebaseContext.Provider value={user}>{children}</FirebaseContext.Provider>
  );
};

export const useProfile = () => useContext(FirebaseContext);

export default FirebaseAuthProvider;
