import Header from "modules/eligibilty/Header";
import React from "react";

const CommonLayout = ({ children }) => (
  <div>
    <Header />
    {children}
  </div>
);
export default CommonLayout;
