import React from "react";
import { RefreshCircleOutline } from "react-ionicons";
import { useHistory } from "react-router";
import { useAuth } from "reactfire";
import ROUTES from "./../../libs/router/constants";

function Logout() {
  const auth = useAuth();
  const history = useHistory();
  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className="nav-link d-flex"
        onClick={async (e) => {
          e.preventDefault();
          await auth.signOut();
          history.push(ROUTES.LOGIN);
        }}
      >
        <RefreshCircleOutline color={"#00000"} height="20px" width="20px" />{" "}
        Logout{" "}
      </a>
    </div>
  );
}

export default Logout;
