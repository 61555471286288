import { ELIGIBILITY, LEADS } from "libs/firebase/constants";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import countries from "libs/constants/countries";
import Modal from "react-bootstrap4-modal";

import qualifications from "libs/constants/qualifications";

const columns = [
  {
    name: "Date",
    selector: "createdAt",
    sortable: true,
    right: false,
  },
  {
    name: "Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "Country Interested",
    selector: "countryIntrust",
    sortable: true,
    right: false,
  },
  {
    name: "Highest Qualification",
    selector: "highestQualification",
    sortable: true,
    right: false,
  },
  {
    name: "Year of Passing",
    selector: "passingYear",
    sortable: true,
    right: false,
  },
  {
    name: "Gap if Any",
    selector: "gap",
    sortable: true,
    right: false,
  },
  {
    name: "Percentage (%)",
    selector: "percentage",
    sortable: true,
    right: false,
  },
  {
    name: "English Exam",
    selector: "examType",
    sortable: false,
    right: false,
  },
  {
    name: "Result",
    selector: "result",
    sortable: false,
    right: false,
  },
  {
    name: "Is Eligible?",
    selector: "isEligible",
    sortable: true,
    right: true,
  },
];

function EligibilityDashboard() {
  const [filter, setFilter] = useState({});
  const [sideBar, setSideBar] = useState(false);
  const [leadDetail, setLeadDetails] = useState({});

  let { data } = useFirestoreCollectionData(useFirestore().collection(LEADS));
  const { data: eligibilities } = useFirestoreCollectionData(
    useFirestore().collection(ELIGIBILITY).orderBy("updatedAt", "desc")
  );
  console.log("ELIG. Dash");
  let filterKeys = Object.keys(data[0]).filter(function (value, index, arr) {
    return (
      value === "percentage" ||
      value === "countryIntrust" ||
      value === "highestQualification" ||
      value === "passingYear" ||
      value === "pte"
    );
  });

  const IsUserEligible = (
    refusalCountry,
    passingYear,
    percentage,
    ielts,
    examType,
    pte,
    refusal,
    countryIntrust,
    highestQualification
  ) => {
    const countryData = eligibilities?.filter(
      ({ country }) => country === countryIntrust
    );
    const singleData = countryData?.find(
      (obj) => obj?.highestQualification === highestQualification
    );

    if (percentage < singleData?.percentage) {
      return false;
    }

    if (passingYear < singleData?.passingYear) {
      return false;
    }

    if (refusal === "yes") {
      if (singleData?.previousRefusal === "") {
        return true;
      } else if (singleData?.previousRefusal === refusalCountry) {
        return false;
      }
    }

    if (examType === "PTE") {
      const count = Object.entries(pte).filter(
        (val) =>
          singleData?.pte?.notLessThanPTE >= val[1] && val[0] != "overAll"
      )?.length;
      if (count <= singleData?.pte?.overAllMinimumPTE) {
        if (
          pte?.overAll >= singleData?.pte?.overAllMinimumPTE &&
          pte?.overAll <= singleData?.pte?.overAllMaximumPTE
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else if (examType === "IELTS") {
      const count = Object.entries(ielts).filter(
        (val) =>
          singleData?.ielts?.notLessThanIELTS >= val[1] && val[0] !== "overAll"
      )?.length;
      if (count <= singleData?.ielts?.notLessThanInIELTS) {
        if (
          ielts?.overAll >= singleData?.ielts?.overAllMinimumIELTS &&
          ielts?.overAll <= singleData?.ielts?.overAllMaximumIELTS
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    return true;
  };

  const getScores = (obj) => {
    if (obj?.examType === "IELTS") {
      return Object.entries(obj?.ielts)
        ?.map((_) => `${_[0]?.charAt(0).toUpperCase()}: ${_[1]}`)
        .join(", ");
    } else {
      return Object.entries(obj?.pte)
        ?.map((_) => `${_[0]?.charAt(0).toUpperCase()}: ${_[1]}`)
        .join(", ");
    }
  };

  data = data
    ?.map((obj) => ({
      ...obj,
      percentage: `${obj.percentage} %`,
      gap: `${obj.gap} Year(s)`,
      createdAt: moment(new Date(obj.createdAt.toDate())).format("LL"),
      isEligible: IsUserEligible(
        obj.refusalCountry,
        obj.passingYear,
        obj.percentage,
        obj.ielts,
        obj.examType,
        obj.pte,
        obj.refusal,
        obj.countryIntrust,
        obj.highestQualification
      )
        ? "Yes"
        : "No",
      examType: obj?.examType,
      result: getScores(obj),
    }))
    ?.map((obj) => {
      // delete obj?.ielts;
      delete obj?.updatedAt;
      delete obj?.uid;
      delete obj?.NO_ID_FIELD;
      return obj;
    })
    ?.filter((obj) => {
      let result = true;
      if (filter?.percentage) {
        result =
          result &&
          +obj?.percentage?.split("%")[0].trim() >= filter?.percentage;
      }

      if (filter?.passingYear) {
        result = result && +obj?.passingYear === filter?.passingYear;
      }
      if (filter?.highestQualification) {
        result =
          result && obj?.highestQualification == filter?.highestQualification;
      }
      if (filter?.countryIntrust) {
        result = result && obj?.countryIntrust === filter?.countryIntrust;
      }

      return result;
    });

  // function convertArrayOfObjectsToCSV(array) {
  //   let result;

  //   const columnDelimiter = ",";
  //   const lineDelimiter = "\n";
  //   const keys = Object.keys(data[0]);

  //   result = "";
  //   result += keys.join(columnDelimiter);
  //   result += lineDelimiter;

  //   array.forEach((item) => {
  //     let ctr = 0;
  //     keys.forEach((key) => {
  //       if (ctr > 0) result += columnDelimiter;

  //       result += item[key];

  //       ctr++;
  //     });
  //     result += lineDelimiter;
  //   });

  //   return result;
  // }

  // function downloadCSV(array) {
  //   const link = document.createElement("a");
  //   let csv = convertArrayOfObjectsToCSV(array);
  //   if (csv == null) return;

  //   const filename = "export.csv";

  //   if (!csv.match(/^data:text\/csv/i)) {
  //     csv = `data:text/csv;charset=utf-8,${csv}`;
  //   }

  //   link.setAttribute("href", encodeURI(csv));
  //   link.setAttribute("download", filename);
  //   link.click();
  // }

  function switchFilters(module) {
    let element = <div />;
    switch (module) {
      case "countryIntrust":
        element = (
          <div className="percentage-filter">
            <select
              className="form-control"
              onChange={(e) =>
                setFilter({ ...filter, countryIntrust: e.currentTarget.value })
              }
            >
              <option value="">- SELECT COUNTRY -</option>
              {countries?.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>
        );
        break;
      case "highestQualification":
        element = (
          <div className="percentage-filter">
            <select
              className="form-control"
              onChange={(e) =>
                setFilter({
                  ...filter,
                  highestQualification: e.currentTarget.value,
                })
              }
            >
              <option value="">- SELECT -</option>
              {qualifications?.map((qualification) => (
                <option key={qualification} value={qualification}>
                  {qualification}
                </option>
              ))}
            </select>
          </div>
        );
        break;
      case "passingYear":
        element = (
          <div className="percentage-filter">
            <input
              type="number"
              className="form-control"
              placeholder="Passing Year"
              onChange={(e) =>
                setFilter({ ...filter, passingYear: +e.currentTarget.value })
              }
            />
          </div>
        );
        break;
      case "percentage":
        element = (
          <div className="percentage-filter">
            <input
              type="number"
              className="form-control"
              placeholder="Min Percentage"
              onChange={(e) =>
                setFilter({ ...filter, percentage: +e.currentTarget.value })
              }
            />
          </div>
        );
        break;
      case "pte":
        element = (
          <div className="pte-filter">
            <input
              type="number"
              className="form-control mb-1"
              placeholder="Min Reading"
            />
            <input
              type="number"
              className="form-control mb-1"
              placeholder="Min Writing"
            />
            <input
              type="number"
              className="form-control mb-1"
              placeholder="Min Listening"
            />
            <input
              type="number"
              className="form-control mb-1"
              placeholder="Min Speaking"
            />
            <input
              type="number"
              className="form-control"
              placeholder="Min Overall"
            />
          </div>
        );
        break;
      default:
        return element;
    }
    return element;
  }

  return (
    <div className="">
      {/* <button onClick={(e) => downloadCSV(data)}>Export</button> */}
      <div className="admin-eligibility-table">
        <div className="filter-wrapper">
          {Object.keys(filter)?.length <= 0 ? (
            <button
              className="btn btn-default btn-sm ml-auto d-flex mb-3"
              onClick={(e) => setSideBar(true)}
            >
              Filter Results{" "}
            </button>
          ) : (
            <button
              className="btn btn-danger btn-sm ml-auto d-flex mb-3"
              onClick={(e) => {
                setSideBar(false);
              }}
            >
              Clear Filters{" "}
            </button>
          )}
        </div>
        <div className="d-flex">
          <div className="w-100">
            <DataTable
              title="Eligibility Lists"
              columns={columns}
              data={data}
              onRowClicked={(e) => {
                setLeadDetails(e);
                console.log(e);
              }}
              // customStyles={customStyles}
              pagination
              selectableRows
              dense
            />
            <Modal visible={Object.keys(leadDetail).length > 0 ? true : false}>
              <div className="modal-header">
                <h5 className="modal-title">Lead Details</h5>
              </div>
              <div className="modal-body">
                <table class="table">
                  <tbody>
                    <tr>
                      <th scope="row">Is Eligible</th>
                      <td>{leadDetail?.isEligible}</td>
                    </tr>
                    <tr>
                      <th scope="row">Name</th>
                      <td>{leadDetail?.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Phone Number</th>
                      <td>{leadDetail?.phone}</td>
                    </tr>
                    <tr>
                      <th scope="row">Email</th>
                      <td>{leadDetail?.email}</td>
                    </tr>
                    <tr>
                      <th scope="row">Country Preference </th>
                      <td>{leadDetail?.countryIntrust}</td>
                    </tr>

                    <tr>
                      <th scope="row">Education </th>
                      <td>
                        {leadDetail?.highestQualification} (
                        {leadDetail?.passingYear})
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Percentage </th>
                      <td>{leadDetail?.percentage}</td>
                    </tr>
                    <tr>
                      <th scope="row">Refusal Country </th>
                      <td>
                        {leadDetail?.refusalCountry !== ""
                          ? leadDetail?.refusalCountry
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">Gap (if any) </th>
                      <td>{leadDetail?.gap}</td>
                    </tr>

                    <tr>
                      <th scope="row">English Eligibility </th>
                      <td>
                        {leadDetail?.examType === "IELTS" ? (
                          <div>
                            IELTS
                            <p style={{ whiteSpace: "normal" }}>
                              {Object.entries(leadDetail?.ielts)
                                .map((sc, i) => sc.join(" : "))
                                .join("  ")}
                            </p>
                          </div>
                        ) : (
                          <div>
                            PTE
                            {leadDetail?.pte !== undefined && (
                              <p style={{ whiteSpace: "normal" }}>
                                {Object.entries(leadDetail?.pte)
                                  .map((sc, i) => sc.join(" : "))
                                  .join("  ")}
                              </p>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(_) => setLeadDetails({})}
                >
                  Cancel
                </button>
              </div>
            </Modal>
          </div>

          {sideBar && (
            <div className="filter-wrapper ml-3">
              <div className="filter-inner">
                <div className="list-group">
                  {filterKeys.map((fk, i) => (
                    <button
                      key={i}
                      onClick={() => {
                        document
                          .querySelector(`.filter-inner-${i}`)
                          .classList.remove("d-none");
                      }}
                      className="list-group-item list-group-item-action flex-column align-items-start"
                    >
                      <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1 text-uppercase">
                          {fk === "refusalCountry" ? "Refusal Country" : fk}
                        </h5>
                      </div>
                      <div className={`d-none filter-inner-${i}`}>
                        {switchFilters(fk)}
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EligibilityDashboard;
