import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import ROUTES from "./constants";

import LoginPage from "modules/auth/LoginPage";
import EligibilityForm from "modules/eligibilty/EligibilityForm";
import EligibilityDashboard from "modules/eligibilty/Admin/EligibilityDashboard";
import AddEligibility from "modules/eligibilty/Admin/AddEligibility";
import EligibilityLayout from "modules/layouts/eligibilityLayout";
import CommonLayout from "modules/layouts/commonLayout";
import { useProfile } from "libs/firebase/hooks";
import ThankYouLead from "modules/eligibilty/Thankyou";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/Admin" component={LoginPage} />
        <PublicRoute
          exact
          path="/"
          component={EligibilityForm}
          layout={CommonLayout}
        />
        {/* Eligibility Modules */}
        <PublicRoute
          path="/eligibility-check"
          component={EligibilityForm}
          layout={CommonLayout}
        />
        <PublicRoute
          path={ROUTES.THANKYOU_LEAD}
          component={ThankYouLead}
          layout={CommonLayout}
        />
        <PublicRoute
          path={ROUTES.ELIGIBILITY_DASHBOARD}
          component={EligibilityDashboard}
          layout={EligibilityLayout}
        />
        <PrivateRoute
          path="/manage-eligibility/"
          component={AddEligibility}
          layout={EligibilityLayout}
        />
        <PrivateRoute
          path="/add-eligibility/"
          component={AddEligibility}
          layout={EligibilityLayout}
        />
      </Switch>
    </BrowserRouter>
  );
};

function PublicRoute({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

function PrivateRoute({ component: Component, layout: Layout, ...rest }) {
  const profile = useProfile();
  console.log(profile);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (profile) {
          return (
            <Layout {...props}>
              <Component {...props} />
            </Layout>
          );
        } else {
          return <Redirect to={ROUTES.LOGIN} />;
        }
      }}
    />
  );
}

export default Router;
