import { Suspense } from "react";
import { FirebaseAppProvider } from "reactfire";

import firebaseConfig from "libs/firebase/firebaseConfig";
import Router from "libs/router";
import Loader from "components/Loader";
import FirebaseAuthProvider from "libs/firebase/hooks";

function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
      <Suspense fallback={<Loader title="Loading..." />}>
        <FirebaseAuthProvider>
          <Router />
        </FirebaseAuthProvider>
      </Suspense>
    </FirebaseAppProvider>
  );
}

export default App;
