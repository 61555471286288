import React from "react";
import { Spinner } from "reactstrap";

export default function OverlayLoader({ title }) {
  return (
    <div className="overlay-loader">
      <div className="card shadow-sm">
        <div className="card-body d-flex flex-row justify-content-center align-items-center">
          <Spinner type="grow" color="dark" />
          {title && <span className="ml-2">{title}</span>}
        </div>
      </div>
    </div>
  );
}
