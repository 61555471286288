/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import ROUTES from "libs/router/constants";
import Logout from "modules/auth/Logout";
import React from "react";
import { Link } from "react-router-dom";
import { AddOutline, CubeOutline, FilterOutline } from "react-ionicons";

const EligibilityLayout = ({ children }) => (
  <div>
    <nav
      id="sidenav-main"
      className="navbar-vertical fixed-left navbar-light navbar navbar-expand-md"
    >
      <div className="container-fluid">
        <button className="navbar-toggler" type="button">
          <span className="navbar-toggler-icon"></span>
        </button>
        <Link to="/">
          <img
            alt="..."
            className="navbar-brand-img"
            src="images/mainlogo.png"
          />
        </Link>

        <ul className="align-items-center d-md-none nav">
          <li className="dropdown nav-item">
            <a
              aria-haspopup="true"
              href="#"
              className="nav-link-icon nav-link"
              aria-expanded="false"
            >
              <i className="ni ni-bell-55"></i>
            </a>
            <div
              tabIndex="-1"
              role="menu"
              aria-labelledby="navbar-default_dropdown_1"
              aria-hidden="true"
              className="dropdown-menu-arrow dropdown-menu dropdown-menu-right"
            >
              <button
                type="button"
                tabIndex="0"
                role="menuitem"
                className="dropdown-item"
              >
                Action
              </button>
              <button
                type="button"
                tabIndex="0"
                role="menuitem"
                className="dropdown-item"
              >
                Another action
              </button>
              <div tabIndex="-1" className="dropdown-divider"></div>
              <button
                type="button"
                tabIndex="0"
                role="menuitem"
                className="dropdown-item"
              >
                Something else here
              </button>
            </div>
          </li>
          <li className="dropdown nav-item">
            <a
              aria-haspopup="true"
              href="#"
              className="nav-link"
              aria-expanded="false"
            >
              <div className="align-items-center media">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src="/argon-dashboard-react/static/media/team-1-800x800.fa5a7ac2.jpg"
                  />
                </span>
              </div>
            </a>
            <div
              tabIndex="-1"
              role="menu"
              aria-hidden="true"
              className="dropdown-menu-arrow dropdown-menu dropdown-menu-right"
            >
              <div tabIndex="-1" className="noti-title dropdown-header">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </div>
              <a
                tabIndex="0"
                role="menuitem"
                className="dropdown-item"
                href="#/admin/user-profile"
              >
                <i className="ni ni-single-02"></i>
                <span>My profile</span>
              </a>
              <a
                tabIndex="0"
                role="menuitem"
                className="dropdown-item"
                href="#/admin/user-profile"
              >
                <i className="ni ni-settings-gear-65"></i>
                <span>Settings</span>
              </a>
              <a
                tabIndex="0"
                role="menuitem"
                className="dropdown-item"
                href="#/admin/user-profile"
              >
                <i className="ni ni-calendar-grid-58"></i>
                <span>Activity</span>
              </a>
              <a
                tabIndex="0"
                role="menuitem"
                className="dropdown-item"
                href="#/admin/user-profile"
              >
                <i className="ni ni-support-16"></i>
                <span>Support</span>
              </a>
              <div tabIndex="-1" className="dropdown-divider"></div>
              <a
                href="#pablo"
                tabIndex="0"
                role="menuitem"
                className="dropdown-item"
              >
                <i className="ni ni-user-run"></i>
                <span>Logout</span>
              </a>
            </div>
          </li>
        </ul>
        <div className="collapse navbar-collapse">
          <div className="navbar-collapse-header d-md-none">
            <div className="row">
              <div className="collapse-brand col-6">
                <a href="#/admin/index"></a>
              </div>
              <div className="collapse-close col-6">
                <button className="navbar-toggler" type="button">
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
          <form className="mt-4 mb-3 d-md-none">
            <div className="input-group-rounded input-group-merge input-group">
              <input
                aria-label="Search"
                placeholder="Search"
                type="search"
                className="form-control-rounded form-control-prepended form-control"
              />
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <span className="fa fa-search"></span>
                </span>
              </div>
            </div>
          </form>
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to={ROUTES.ELIGIBILITY_DASHBOARD}>
                <CubeOutline
                  color={"#00000"}
                  // title={}
                  height="20px"
                  width="20px"
                />
                Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={ROUTES.MANAGE_ELIGIBILITY}>
                <FilterOutline color={"#00000"} height="20px" width="20px" />{" "}
                List Eligibilities
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={ROUTES.ADD_ELIGIBILITY}>
                <AddOutline color={"#00000"} height="20px" width="20px" />
                Add Eligibility
              </Link>
            </li>
          </ul>
          <hr className="my-3" />
          <h6 className="navbar-heading text-muted">Settings</h6>
          <ul className="mb-md-3 navbar-nav">
            <li className="nav-item">
              <Logout />
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div className="main-content">{children}</div>
  </div>
);
export default EligibilityLayout;
